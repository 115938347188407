<template>
  <section class="cont">
     <Breadcrumb :crumbs="crumbs"/>
     <el-row class="content-box">
        <!-- 查询框 -->
        <el-row class="search-box">
           <el-row class="search-row">
              <el-row class="search-item">
                 <label>楼栋/单元：</label>
                 <el-cascader
                       class="width-220 m-right-10"
                       :key="buildKey"
                       v-model="buildIds"
                       :options="buildings"
                       @change="changeBuilding"
                       clearable></el-cascader>
              </el-row>
              <el-row class="search-item">
                 <label>设备类型：</label>
                 <el-select  class="width-220 m-right-10"  v-model="deviceTypeId" :placeholder="$t('msg.select')" clearable>
                    <el-option v-for="(item,index) in deviceTypes" :key="index" :label="item.name" :value="item.id"></el-option>
                 </el-select>
              </el-row>
           </el-row>
           <el-row class="search-row">
              <el-row class="search-item">
                 <label>房间号：</label>
                 <el-select  class="width-220 m-right-10"   @focus="getRooms" v-model="roomId" :placeholder="$t('msg.select')" filterable clearable>
                    <el-option
                          v-for="item in rooms"
                          :key="item.id"
                          :label="item.roomNo"
                          :value="item.id">
                    </el-option>
                 </el-select>
              </el-row>
              <el-row class="search-item">
                 <label>报警时间：</label>
                 <date-packer class="width-220"  :datePacker="dateValue" @setDatePacker="getDatePacker"></date-packer>
              </el-row>
              <el-button  class="m-bottom-5 bg-gradient" type="primary" @click="handleQuery(true)" ><span v-text="$t('msg.search')">搜索</span></el-button>
              <el-button  class="m-bottom-5" type="primary" @click="handleReset(false)" plain><span v-text="$t('msg.reset')">重置</span></el-button>
           </el-row>
        </el-row>

       <!-- 表格 -->
           <!-- <hotel-floor :floorOpt="floors" @setFloorClick="getFloorClick"/> -->
           <el-table
                   v-loading="loading"
                   :data="tableData"
                   border fit
                   highlight-current-row
                   style="width: 100%"
                   :stripe="true"
                   >
             <el-table-column :label="$t('msg.number')" width="60">
                <template slot-scope="scope">
                   {{scope.$index + 1}}
                </template>
             </el-table-column>
             <el-table-column prop="roomNo" :label="$t('msg.room_num')" min-width="110"></el-table-column>
             <el-table-column prop="deviceName" :label="$t('msg.dev_name')" min-width="160"></el-table-column>
             <el-table-column prop="versionsName" :label="$t('msg.dev_type')" min-width="160"></el-table-column>
             <el-table-column prop="content" :label="$t('msg.msg_content')" :min-width="msgWidth"></el-table-column>
             <el-table-column prop="createTime" :label="$t('msg.alarm_time')" min-width="150" sortable></el-table-column>
           </el-table>
       <!-- 分页 -->
       <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="currChange"/>
     </el-row>
  </section>
</template>
<script>
   import { urlObj } from '@/api/interface'
   import { mapState } from 'vuex'
   export default {
      computed:{
         ...mapState(['hotelInfo'])
      },
      watch:{
         hotelInfo:{
            handler(newVal,oldVal){
               if(newVal.id !== oldVal.id && oldVal){
                  this.hotelId = this.hotelInfo.id
                  this.getAlarmList()
                  this.getCategory()
                  this.getBuildings()
                  this.buildIds = [];
                  this.roomId = '';
                  this.deviceTypeId = '';
               }
            },
            deep:true
         },
         buildings(newVal){
            if(newVal.length !== 0) this.buildKey = newVal[0].value
         }
      },
      data() {
         return {
            crumbs: new Map([['AIOT'], ['报警管理'], ['设备报警']]),
            tableData: [],    // 表格数据
            buildings: [],    // 楼栋列表
            floors: [],       // 楼层列表
            emptyFloor: [],   // 空楼层列表
            rooms:[],         // 房间列表
            deviceTypes: [],  // 设备类型列表
            total: 0,         // 数据总数
            loading: true,    // 表格加载效果
            boxIcon: true,    // 侧边栏显示隐藏
            dateValue: [],    // 日期段
            floor: '',        // 当前楼层ID
            buildIds: [],     // 当前楼栋IDs
            buildId: '',      // 当前楼栋ID
            buildKey: '',     // 联级器索引
            msgWidth: 0,      // 内容宽度
            /* 查询列表参数 */
            limit: 1,         // 每页数
            page: 1,          // 当前页
            hotelId: '',      // 酒店ID
            deviceTypeId: '', // 设备类型ID
            roomId: '',       // 房间ID
            stTime: '',       // 开始日期
            edTime: '',       // 结束日期
         }
      },
      mounted () {
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.hotelId = this.hotelInfo.id
         this.getAlarmList()
         this.getCategory()
         this.getBuildings()
      },
      methods: {
         // 获取警报列表
         getAlarmList(){
            const url = urlObj.getAlarmList + `?limit=${this.limit}&page=${this.page}`
            const param = {
               hotelId: this.hotelId,
               deviceTypeId: this.deviceTypeId,
               roomId: this.roomId,
               floor: this.floor,
               stTime: this.stTime,
               edTime: this.edTime
            }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success) {
                  this.loading = false
                  this.total = res.total
                  let tableData = res.records
                  tableData.forEach(item => {
                     item.content = this.filterAlarmInfo(item.roomNo, item.versionsName, item.logType)
                  })
                  this.tableData = tableData
               }
            })
         },
         // 校验报警消息
         filterAlarmInfo (roomNo, alarmType, battery) {
            let electricityAlarm = roomNo + '房间设备电量不足'
            // 语言判断
            if (localStorage.getItem('lang') === 'en_US') {
               electricityAlarm = 'Device in room ' + roomNo + ' ，low battery '
            }
            switch (battery) {
               case 'POWER_ALARM':
                  battery = electricityAlarm
                  break
               default:
                  battery = '—'
                  break
            }
            return battery
         },
         // 获取楼栋列表
         getBuildings(){
            let str = '/hotel/build/getBuildAndUnitList'
            const url = str + `/${this.hotelId}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  let buildings = res.records
                  buildings = buildings.map(item1 => {
                     let children = []
                     if (item1.buildUnits.length > 0){
                        children = item1.buildUnits.map(item2 => {
                           return {value: item2.id, label: item2.unitName}
                        })
                     }
                     return {value: item1.id, label: item1.buildName, children}
                  })
                  this.buildings = buildings
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 改变当前楼栋
         changeBuilding(buildIds) {
            this.buildId = buildIds[1];
            this.roomId = '';
            // this.getFloors()
         },
         // 获取当前楼层列表
         getFloors() {
            let url = urlObj.getUnit + `/${this.buildId}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  let data = res.records
                  let beginFloor = data.beginFloor
                  let endFloor = data.endFloor
                  if (!endFloor || endFloor <= 0) return
                  /*
                  * 注意数组是引用类型
                  * 使用JSON.parse(JSON.stringify())实现深拷贝
                  * 避免this.emptyFloor（原数组）的值被修改
                  * */
                  let floors = JSON.parse(JSON.stringify(this.emptyFloor))
                  let obj = {}
                  for (let i = beginFloor; i <= endFloor; i++) {
                     obj.value = i
                     obj.label = i
                     floors.push(obj)
                     obj = {}
                  }
                  this.floors = floors
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 点击当前楼层
         getFloorClick(val) {
            this.floor = val.floor
            this.roomId = ''
            this.getAlarmList()
         },
         // 获取房间列表
         getRooms(){
            if (this.buildIds.length === 0) return
            const url = urlObj.getRoomLists
            let param = {
               hotelId: this.hotelId,
               buildUnitId: this.buildId,
               // floor: this.floor
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  let rooms = res.records
                  rooms = rooms.map(item => {
                     return {id: item.id, roomNo: item.roomNo}
                  })
                  this.rooms = rooms
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取设备类型
         getCategory(){
            const url = '/device-hotel/device/getDeviceListByRoomType'
            const param = {
               hotelId:this.hotelId,
               roomScope:"ALL"
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  let deviceTypes = res.records;
                  this.deviceTypes = deviceTypes.map(item => {
                     return {id: item.deviceTypeId, name: item.versionsName}
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取日期
         getDatePacker(val) {
            this.dateValue = val
         },
         // 改变每页数
         pageChange(num){
            this.limit = num
            this.getAlarmList()
         },
         // 改变当前页
         currChange(num){
            this.page = num
            this.getAlarmList()
         },
         // 搜索警报
         handleQuery(){
            if (this.dateValue) {
              this.stTime = this.dateValue[0]
              this.edTime = this.dateValue[1]
            }
            this.getAlarmList()
         },
         // 重置搜索
         handleReset(){
            // 楼栋
            this.buildIds = []
            // 楼层
            this.floor = ''
            this.floors = this.emptyFloor
            // 房间号
            this.rooms = []
            this.roomId = ''
            // 设备类型
            this.deviceTypeId = ''
            // 日期
            this.dateValue = []
            this.stTime = ''
            this.edTime = ''
            this.getAlarmList()
         },
      }
   }
</script>